import React from "react";
import Layout from "../../components/Layout";
import Tabs from "../../ui-blocks/Tabs";
import PrevNextNavFooter from "../../ui-blocks/PrevNextNavFooter/PrevNextNavFooter";
import Typography_Hero_Image_jpg from "../../images/5. Typography/Typography_Hero_Image.jpg";
import FontSelector from "../../ui-blocks/FontSelector/FontSelector";

import Typography_Body_Leading_svg from "../../images/5. Typography/Desktop/Toyota_Desktop_Typography_Body_Leading.svg";
import Typography_Body_Paragraph_Spacing_svg from "../../images/5. Typography/Desktop/Toyota_Desktop_Typography_Body_Paragraph_Spacing.svg";
import Typography_Body_Weight_svg from "../../images/5. Typography/Desktop/Toyota_Desktop_Typography_Body_Weight.svg";

import Typography_Headline_Alignment_svg from "../../images/5. Typography/Desktop/Toyota_Desktop_Typography_Headline_Alignment.svg";
import Typography_Headline_Kerning_svg from "../../images/5. Typography/Desktop//Toyota_Desktop_Typography_Headline_Kerning.svg";
import Typography_Headline_Leading_svg from "../../images/5. Typography/Desktop/Toyota_Desktop_Typography_Headline_Leading.svg";
import Typography_Headline_Weight_svg from "../../images/5. Typography/Desktop/Toyota_Desktop_Typography_Headline_Weight.svg";

import Typography_Legal_Alignment_svg from "../../images/5. Typography/Desktop/Toyota_Desktop_Typography_Legal_Alignment.svg";
import Typography_Legal_Leading_svg from "../../images/5. Typography/Desktop/Toyota_Desktop_Typography_Legal_Leading.svg";
import Typography_Legal_Weight_svg from "../../images/5. Typography/Desktop/Toyota_Desktop_Typography_Legal_Weight.svg";

import Typography_Subhead_Kerning_svg from "../../images/5. Typography/Desktop/Toyota_Desktop_Typography_Subhead_Kerning.svg";
import Typography_Subhead_Leading_svg from "../../images/5. Typography/Desktop/Toyota_Desktop_Typography_Subhead_Leading.svg";
import Typography_Subhead_Paragraph_Spacing_svg from "../../images/5. Typography/Desktop/Toyota_Desktop_Typography_Subhead_Space_After.svg";
import Typography_Subhead_Weight_svg from "../../images/5. Typography/Desktop/Toyota_Desktop_Typography_Subhead_Weight.svg";

const Typography = (props) => {
  return (
    <Layout>
      <div
        className="page-header image-header"
        id="overview"
        data-navindex="1"
        style={{ backgroundColor: "#000000", height: "650px" }}
      >
        <div
          className="content-flex"
          style={{ color: "#fff", top: "", textAlign: "left" }}
        >
          <h1
            className="copy"
            style={{ color: "#fff", top: "", textAlign: "left" }}
          >
            Toyota Type is our own distinctive font. It's approachable, human,
            yet contemporary. Utilising Toyota Type throughout our
            communications will ensure consistency and recognition across all
            touchpoints.
          </h1>
        </div>
        <div className="background" style={{ backgroundColor: "#000000" }}>
          <div style={{ height: "100%" }}>
            <img alt="" src={Typography_Hero_Image_jpg} />
          </div>
        </div>
      </div>

      <section className="component Section" id="toyota-type">
        <div className="section-header darker-background">
          <div className="container">
            <h2 className="ui header">Toyota type</h2>
          </div>
        </div>

        <div className="blog-content darker-background">
          <div className="container onecolumn">
            <div className="copy">
              Toyota Type can be used for Brand Campaigns and the Messaging
              Platform.
            </div>
          </div>
        </div>

        <div className="blog-content darker-background">
          <div className="container onecolumn">
            <h3 className="title" style={{ color: "#000" }}>
              Primary font weights
            </h3>
            <div className="copy">
              There are four preferred and two optional weights for use within
              the Toyota Type family. Each weight includes upright and italics.
              The selected and acceptable weights are Book, Regular, Semibold
              and Bold. While additional weights are available within the Toyota
              Type family, these are the only four that should be used unless a
              specific case otherwise demands.
            </div>
          </div>
        </div>

        <div
          className="blog-content darker-background"
          style={{ paddingBottom: "80px" }}
        >
          <div className="container onecolumn">
            <FontSelector
              items={[
                {
                  caption: "Book",
                  style: { fontFamily: "ToyotaType-Book" },
                },
                {
                  caption: "Regular",
                  style: { fontFamily: "ToyotaType-Regular" },
                },
                {
                  caption: "Semibold",
                  style: { fontFamily: "ToyotaType-Semibold" },
                },
                {
                  caption: "Bold",
                  style: {
                    fontFamily: "ToyotaType-Regular",
                    fontWeight: "bold",
                  },
                },
              ]}
            ></FontSelector>
          </div>
        </div>

        <div className="blog-content darker-background">
          <div className="container onecolumn">
            <h3 className="title" style={{ color: "#000" }}>
              Secondary font weights
            </h3>
            <div className="copy spaced-p">
              The optional Toyota Type weights are Light and Black. These may be
              used when appropriate. Light should be used in instances where the
              communications are focused on enhanced technology, precision or
              the weight feels appropriate for the intended message.
            </div>
            <div className="copy spaced-pd">
              Black should be used in instances where stance and presence need
              to be reinforced, or the weight feels appropriate for the intended
              message.
            </div>

            <FontSelector
              items={[
                {
                  caption: "Light",
                  style: { fontFamily: "ToyotaType-Regular" },
                },
                {
                  caption: "Black",
                  style: {
                    fontFamily: "ToyotaType-Regular",
                    fontWeight: "bold",
                  },
                },
              ]}
            ></FontSelector>
          </div>
        </div>
      </section>

      <section className="component Section">
        <div className="section-header darker-background">
          <div className="container">
            <h2 className="ui header">Typography settings</h2>
          </div>
        </div>
        <div className="blog-content darker-background">
          <div className="container onecolumn">
            <div className="copy">
              Below are guidelines for font weight, leading, kerning and
              alignment when using appropriate headlines.
            </div>
          </div>
        </div>

        {/* Navigation works not only for section tags  */}

        <div className="blog-content darker-background" id="headlines">
          <div className="container onecolumn">
            <h3 className="title" style={{ color: "#000" }}>
              1. Headlines
            </h3>
            <Tabs
              section="headlines"
              backgroundColor="white"
              items={[
                {
                  content: {
                    style: "image",
                    image: {
                      svg: Typography_Headline_Weight_svg,
                      style: { height: "400px" },
                    },
                    caption: {
                      copy: "Use Semibold for headlines.",
                    },
                  },
                  label: "Weight",
                },
                {
                  content: {
                    style: "image",
                    image: {
                      svg: Typography_Headline_Leading_svg,
                      style: { height: "400px" },
                    },
                    caption: {
                      copy: "Use 130% auto leading for print and OOH. Use .09em line height for digital communications.",
                      align: "left",
                    },
                  },
                  label: "Leading",
                },
                {
                  content: {
                    style: "image",
                    image: {
                      svg: Typography_Headline_Kerning_svg,
                      style: { height: "400px" },
                    },
                    caption: {
                      copy: "Use optical kerning with manual adjustments as needed for all print and OOH. Use 0 pixel letter-spacing for digital.",
                      align: "left",
                    },
                  },
                  label: "Kerning",
                },
                {
                  content: {
                    style: "image",
                    image: {
                      svg: Typography_Headline_Alignment_svg,
                      style: { height: "400px" },
                    },
                    caption: {
                      copy: "Headlines may be left aligned or centered, but never right aligned.",
                      align: "left",
                    },
                  },
                  label: "Alignment",
                },
              ]}
            />
          </div>
        </div>

        <div className="blog-content darker-background" id="subhead">
          <div className="container onecolumn">
            <h3 className="title" style={{ color: "#000" }}>
              2. Subheads
            </h3>
            <Tabs
              section="subhead"
              backgroundColor="white"
              items={[
                {
                  content: {
                    style: "image",
                    image: {
                      svg: Typography_Subhead_Weight_svg,
                      style: { height: "400px" },
                    },
                    caption: {
                      copy: "Always use Semibold weight for subheads.",
                      align: "left",
                    },
                  },
                  label: "Weight",
                },
                {
                  content: {
                    style: "image",
                    image: {
                      svg: Typography_Subhead_Leading_svg,
                      style: { height: "400px" },
                    },
                    caption: {
                      copy: "Use 115% auto leading for print and OOH. Use .09em line height for digital communications.",
                      align: "left",
                    },
                  },
                  label: "Leading",
                },
                {
                  content: {
                    style: "image",
                    image: {
                      svg: Typography_Subhead_Kerning_svg,
                      style: { height: "400px" },
                    },
                    caption: {
                      copy: "Use optical kerning with manual adjustments as needed for all print and OOH. Use 0 pixel letter spacing for digital.",
                      align: "left",
                    },
                  },
                  label: "Kerning",
                },
                {
                  content: {
                    style: "image",
                    image: {
                      svg: Typography_Subhead_Paragraph_Spacing_svg,
                      style: { height: "400px" },
                    },
                    caption: {
                      copy: "For paragraph spacing use the paragraph palette in Adobe InDesign and Illustrator programs to set the space-after adjustments manually. Adjust space after to be twice the cap height of the body copy.",
                      align: "left",
                    },
                  },
                  label: "Paragraph Spacing",
                },
              ]}
            />
          </div>
        </div>

        <div className="blog-content darker-background" id="body-copy">
          <div className="container onecolumn">
            <h3 className="title" style={{ color: "#000" }}>
              3. Body Copy
            </h3>
            Below are guidelines for font weight, leading and paragraph spacing
            for body copy.
            <Tabs
              section="subhead"
              backgroundColor="white"
              items={[
                {
                  content: {
                    style: "image",
                    image: {
                      svg: Typography_Body_Weight_svg,
                      style: { height: "400px" },
                    },
                    caption: {
                      copy: "Use Book weight for body text 10 points or larger on light backgrounds. Use Regular weight for body text 10 points or smaller reversed out of dark backgrounds.",
                      align: "left",
                    },
                  },
                  label: "Weight",
                },
                {
                  content: {
                    style: "image",
                    image: {
                      svg: Typography_Body_Leading_svg,
                      style: { height: "400px" },
                    },
                    caption: {
                      copy: "Use 130% auto leading for print and OOH.",
                    },
                  },
                  label: "Leading",
                },
                {
                  content: {
                    style: "image",
                    image: {
                      svg: Typography_Body_Paragraph_Spacing_svg,
                      style: { height: "400px" },
                    },
                    caption: {
                      copy: "For paragraph spacing use the paragraph palette in Adobe InDesign and Illustrator programs to set the space-after adjustments manually. Adjust space after to be twice the cap height of the body copy.",
                      align: "left",
                    },
                  },
                  label: "Paragraph Spacing",
                },
              ]}
            />
          </div>
        </div>

        <div className="blog-content darker-background" id="legal-text">
          <div className="container onecolumn">
            <h3 className="title" style={{ color: "#000" }}>
              4. Legal Text
            </h3>
            <Tabs
              section="subhead"
              backgroundColor="white"
              items={[
                {
                  content: {
                    style: "image",
                    image: {
                      svg: Typography_Legal_Weight_svg,
                      style: { height: "400px" },
                    },
                    caption: {
                      copy: "Use Book weight for legal text 6 points or larger on light backgrounds. Use Regular weight for legal text 6 points or smaller reversed out of dark backgrounds.",
                      align: "left",
                    },
                  },
                  label: "Weight",
                },
                {
                  content: {
                    style: "image",
                    image: {
                      svg: Typography_Legal_Leading_svg,
                      style: { height: "400px" },
                    },
                    caption: {
                      copy: "Use 130% auto leading for print and OOH.",
                    },
                  },
                  label: "Leading",
                },
                {
                  content: {
                    style: "image",
                    image: {
                      svg: Typography_Legal_Alignment_svg,
                      style: { height: "400px" },
                    },
                    caption: {
                      copy: "Use 130% auto leading for print and OOH.",
                    },
                  },
                  label: "Alignment",
                },
              ]}
            />
          </div>
        </div>
      </section>

      <PrevNextNavFooter
        previousText="Previous"
        previousHeader="Tagline"
        previousUrl="/brandguidelines/tagline"
        nextText="Next"
        nextHeader="Photography"
        nextUrl="/brandguidelines/photography"
      />
    </Layout>
  );
};
export default Typography;
